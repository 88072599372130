@import "~@angular/material/theming";

// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
@include mat-core();

// define a real custom palette (using http://mcg.mbitson.com)
/* For use in src/lib/core/theming/_palette.scss */
$md-ocprimary: (
  50: #e4e7ee,
  100: #bbc4d4,
  200: #8e9db8,
  300: #60769b,
  400: #3e5885,
  500: #1c3b70,
  600: #193568,
  700: #142d5d,
  800: #112653,
  900: #091941,
  A100: #7996ff,
  A200: #466eff,
  A400: #1346ff,
  A700: #0036f8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-ocaccent: (
  50: #e4eeed,
  100: #bbd4d3,
  200: #8eb7b5,
  300: #609a97,
  400: #3e8481,
  500: #1c6e6b,
  600: #196663,
  700: #145b58,
  800: #11514e,
  900: #093f3c,
  A100: #77fff6,
  A200: #44fff2,
  A400: #11ffef,
  A700: #00f6e6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
