// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "~@angular/material" as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import "./theme.scss";
@import "./typography.scss";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$onlycard-primary: mat.define-palette($md-ocprimary);
$onlycard-accent: mat.define-palette($md-ocaccent, 700);

$onlycard-dark-primary: mat.define-palette($md-ocprimary, 300);
$onlycard-dark-accent: mat.define-palette($md-ocaccent, 200);

// The warn palette is optional (defaults to red).
$onlycard-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $theme-typography: mat.define-typography-config(
//   $font-family: (
//     "Open Sans",
//     "Tajawal",
//   ),
// );

@include mat-core($typography-configuration);
$onlycard-theme: mat.define-light-theme(
  (
    color: (
      primary: $onlycard-primary,
      accent: $onlycard-accent,
      warn: $onlycard-warn,
    ),
  )
);

$onlycard-dark-warn: mat.define-palette(mat.$red-palette, 200);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($onlycard-theme);
$onlycard-theme-dark: mat.define-dark-theme(
  (
    color: (
      primary: $onlycard-dark-primary,
      accent: $onlycard-dark-accent,
      warn: $onlycard-dark-warn,
    ),
  )
);

*::-webkit-scrollbar-track {
  background: #d2f2ff;
}

.dark-mode {
  @include mat.all-component-themes($onlycard-theme-dark);
  &.app-background {
    background: url("./assets/images/background-dark.jpg") no-repeat center fixed;
    background-size: cover;
    color: #fff;
  }

  .color-yellow {
    color: rgb(241, 237, 0);
  }

  .color-orange {
    color: rgb(204, 151, 53);
  }

  .color-blue {
    color: rgb(47, 124, 212);
  }

  .color-green {
    color: rgb(101, 221, 111);
  }

  .color-red {
    color: rgb(221, 101, 101);
  }
  html {
    --scrollbarBG: #002535;
    --thumbBG: #0079b6;
  }
  ::-webkit-scrollbar {
    width: 11px;
  }
  body {
    // scrollbar-width: thin;
    // scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  // ::-webkit-scrollbar-track {
  //   background: var(--scrollbarBG);
  // }
  // ::-webkit-scrollbar-thumb {
  //   background-color: var(--thumbBG);
  //   border-radius: 6px;
  //   border: 3px solid var(--scrollbarBG);
  // }
}
html,
body {
  height: 100%;
}

body {
  margin: 0;
}
html {
  // --scrollbarBG: #cfd8dc;
  // --thumbBG: #90a4ae;
}
::-webkit-scrollbar {
  // width: 11px;
}
body {
  // scrollbar-width: thin;
  // scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
* {
  scroll-behavior: smooth;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

table {
  font-size: 15px !important;
}

.no-padding {
  padding: 0 !important;
}
.app-background {
  background: url("./assets/images/background-light.jpg") no-repeat center center fixed;
  background-size: cover;
}
.transparent {
  background: transparent !important;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// span,
// div,
// a,
// button,
// ul,
// li,
// b,
// div,
// input,
// textarea,
// u {
//   font-family: "Open Sans", "Tajawal", sans-serif !important;
//   transition: background 0.5s linear;
// }

.spacer {
  flex: 1 1 auto;
}

.space {
  margin: 0 8px;
}

.inline {
  display: inline;
}

.back-button {
  display: inline;
}

input,
button,
textarea,
select {
  -webkit-transform: translate3d(0, 0, 0) !important;
  -webkit-user-select: auto !important;
}
.darken {
  filter: brightness(0);
}
.lighten {
  filter: brightness(100);
}
.darken-font {
  color: rgb(71, 71, 71) !important;
}
.admin-page-header {
  background: transparent !important;
  padding: 0 !important;
}
.admin-page-container {
  padding: 16px;
}
textarea {
  resize: none !important;
}
.color-yellow {
  color: rgb(219, 217, 89);
}

.color-orange {
  color: rgb(192, 125, 0);
}

.color-green {
  color: rgb(32, 117, 40);
}

.color-blue {
  color: rgb(19, 101, 194);
}

.color-red {
  color: rgb(209, 51, 51);
}

.hidden {
  display: none !important;
}